import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { H2, Text } from '../../common/Primitives';
import { Column } from '../../layout/Column';
import { FluidGrid } from '../../layout/FluidGrid';
import { FullWidthBackground } from '../../layout/FullWidthBackground';

type NumbersBlockProps = {
  title: string;
  figures: { count: string; text: string }[];
};

export function NumbersBlock({ title, figures }: NumbersBlockProps) {
  return (
    <FullWidthBackground forwardedAs="section" invertColors>
      <Column space={theme.space.l} align="center">
        <H2 size="xxl">{title}</H2>

        <FluidGrid max="400px">
          {figures.map(({ count, text }) => (
            <Column key={count} align="center" space={0}>
              <Text textAlign="center" size="xxl" bold>
                {count}
              </Text>
              <Text textAlign="center" size="m">
                {text}
              </Text>
            </Column>
          ))}
        </FluidGrid>
      </Column>
    </FullWidthBackground>
  );
}
