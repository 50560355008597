/**
 * When you need interactive functionality on an element / wrapper
 * but without any inherent styling
 */
export const getButtonProps = <T = HTMLDivElement>(handler: (e: EventTarget) => void) => ({
  role: 'button',
  tabIndex: 0,
  onClick: (e: React.MouseEvent<T>) => handler(e.target),
  onKeyDown: (e: React.KeyboardEvent<T>) => e.key === 'Enter' && handler(e.target),
});
