import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import iconAssessments from 'stile-shared/assets/icons/icon_assessments.svg';
import iconAudioNarration from 'stile-shared/assets/icons/icon_audio_narration.svg';
import iconAR from 'stile-shared/assets/icons/icon_aug_vr.svg';
import iconCareers from 'stile-shared/assets/icons/icon_careers.svg';
import iconCollaborative from 'stile-shared/assets/icons/icon_collaborative.svg';
import iconComprehension from 'stile-shared/assets/icons/icon_comprehension.svg';
import iconStileX from 'stile-shared/assets/icons/icon_differentiation.svg';
import iconEngineering from 'stile-shared/assets/icons/icon_engineering.svg';
import iconEscape from 'stile-shared/assets/icons/icon_escape.svg';
import iconExperiments from 'stile-shared/assets/icons/icon_experiments.svg';
import iconGlossary from 'stile-shared/assets/icons/icon_glossary.svg';
import iconMetacognitive from 'stile-shared/assets/icons/icon_metacognitive.svg';
import iconPlanning from 'stile-shared/assets/icons/icon_planning.svg';
import iconPosters from 'stile-shared/assets/icons/icon_posters.svg';
import iconQuizzes from 'stile-shared/assets/icons/icon_quizzes.svg';
import iconScienceNews from 'stile-shared/assets/icons/icon_science_news.svg';
import iconSimulations from 'stile-shared/assets/icons/icon_simulations.svg';
import iconSkills from 'stile-shared/assets/icons/icon_skills.svg';
import iconSocratic from 'stile-shared/assets/icons/icon_socratic.svg';
import iconVideos from 'stile-shared/assets/icons/icon_videos.svg';

/*
 * NOTE: <StaticImage /> doesn't play nicely with animated.div in SlidingToursBlock
 * so we'll load images directly for once - which have been optimized to 600w already
 */
import tourImage01 from 'stile-shared/assets/images/stile-classroom/tour_01_recent_science_news.jpg';
import tourImage02 from 'stile-shared/assets/images/stile-classroom/tour_02_relatable_expert.jpg';
import tourImage03 from 'stile-shared/assets/images/stile-classroom/tour_03_collaboration_discussion.jpg';
import tourImage04 from 'stile-shared/assets/images/stile-classroom/tour_04_learning_goals.jpg';
import tourImage05 from 'stile-shared/assets/images/stile-classroom/tour_05_scaffolded.jpg';
import tourImage06 from 'stile-shared/assets/images/stile-classroom/tour_06_interactive.jpg';
import tourImage07 from 'stile-shared/assets/images/stile-classroom/tour_07_realtime_feedback.jpg';
import tourImage08 from 'stile-shared/assets/images/stile-classroom/tour_08_student_responses.jpg';
import tourImage09 from 'stile-shared/assets/images/stile-classroom/tour_09_feedback.jpg';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { IconCardsBlock } from 'stile-shared/src/components/2020/blocks/IconCardsBlock';
import { MediaBlock } from 'stile-shared/src/components/2020/blocks/MediaBlock';
import { NumbersBlock } from 'stile-shared/src/components/2020/blocks/NumbersBlock';
import { SlidingToursBlock } from 'stile-shared/src/components/2020/blocks/SlidingToursBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { P } from 'stile-shared/src/components/2020/common/Primitives';

import { getPageData } from 'utils/getPageData';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export function Head(props: HeadProps) {
  const slug = props.location.pathname;

  return (
    <SEO slug={slug}>
      {/* NOTE: If we don't preload slide images via <link /> then when we try to fade to the next,
                the image loads during/after tha animation which is pretty meh */}
      {[
        tourImage01,
        tourImage02,
        tourImage03,
        tourImage04,
        tourImage05,
        tourImage06,
        tourImage07,
        tourImage08,
        tourImage09,
      ].map((image) => (
        <link key={image} rel="preload" as="image" href={image} />
      ))}
    </SEO>
  );
}

function StileClassroomPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/stile-classroom/cover_stile_classroom_us.jpg"
            alt="A teacher presents to her class using the Stile app on a wall projection"
          />
        }
      />

      <TextBlock>
        <P>
          Stile is a next-generation phenomena-based science resource. It is purpose-built to
          facilitate vibrant, collaborative learning with a mixture of rich, interactive activities,
          hands-on labs, assessments, and much more.
        </P>
        <P>
          We’ve designed Stile to empower educators. Our learning resources are rigorously crafted
          to support{' '}
          <Link url="/why-choose-stile/evidence-based-pedagogy/">evidence-based pedagogies</Link>{' '}
          that drive the adoption of the NGSS. Our{' '}
          <Link url="/what-is-stile/technology-for-teaching/">technology</Link> is crafted to help
          educators, not replace them. Everything has been battle-tested and refined in thousands of
          classrooms
        </P>
        <P>
          Our classroom resources pair perfectly with{' '}
          <Link url="/what-is-stile/stile-x/">Stile X</Link>, our new student notebook and revision
          guide, to ensure students master scientific concepts and learn critical study and
          note-taking skills to set them up for success in senior science.
        </P>
      </TextBlock>

      <SlidingToursBlock
        title="Tour of Stile"
        items={[
          {
            title: 'Relevant and contextualized',
            slides: [
              {
                text: 'Topics are taught in the context of recent science news, bringing real-world relevance to every lesson.',
                image: tourImage01,
              },
              {
                text: 'Every unit is guided by a relevant and relatable expert, showcasing a wide range of careers.',
                image: tourImage02,
              },
              {
                text: 'Engaging science news stories provide context and opportunities for collaboration and scientific discussion.',
                image: tourImage03,
              },
            ],
          },

          {
            title: 'Engaging for all students',
            slides: [
              {
                text: 'Each lesson starts with learning goals, helping students take responsibility for their learning.',
                image: tourImage04,
              },
              {
                text: 'Lessons are scaffolded from lower- to higher-order thinking, ensuring all students are challenged.',
                image: tourImage05,
              },
              {
                text: 'Interactive simulations allow students to model and run experiments.',
                image: tourImage06,
              },
            ],
          },

          {
            title: 'Key insights into learning',
            slides: [
              {
                text: 'As they work, students’ responses are automatically saved in Stile and instantly visible to the teacher.',
                image: tourImage07,
              },
              {
                text: 'Teachers can provide real-time feedback for their students while teaching in Stile.',
                image: tourImage08,
              },
              {
                text: 'Students receive more feedback, more often.',
                image: tourImage09,
              },
            ],
          },
        ]}
      />

      <MediaBlock layout="center-align-three">
        <StaticImage
          src="../../../../stile-shared/assets/images/stile-classroom/us_classroom_01.jpg"
          alt="Two students attend to plants in their classroom"
        />
        <StaticImage
          src="../../../../stile-shared/assets/images/stile-classroom/us_classroom_02.png"
          alt="A student at a desk raising her hand, seen from behind"
        />
        <StaticImage
          src="../../../../stile-shared/assets/images/stile-classroom/us_classroom_03.jpg"
          alt="A teacher leans over and points to a students work, as another student looks on"
        />
      </MediaBlock>

      <IconCardsBlock
        max="1000px"
        title="For each topic, you’ll find a combination of"
        variant="compact"
        cards={[
          {
            title: 'Formative and summative assessments',
            icon: { src: iconAssessments },
          },
          {
            title: 'Teacher planning resources',
            icon: { src: iconPlanning },
          },
          {
            title: 'Simulations and interactives',
            icon: { src: iconSimulations },
          },
          {
            title: 'Science news articles',
            icon: { src: iconScienceNews },
          },
          {
            title: 'Engineering challenges',
            icon: { src: iconEngineering },
          },
          {
            title: 'Skill builders',
            icon: { src: iconSkills },
          },
          {
            title: 'Escape room challenges',
            icon: { src: iconEscape },
          },
          {
            title: 'Labs and projects',
            icon: { src: iconExperiments },
          },
          {
            title: 'Collaborative activities',
            icon: { src: iconCollaborative },
          },
          {
            title: 'Comprehension activities',
            icon: { src: iconComprehension },
          },
          {
            title: 'Metacognitive activities',
            icon: { src: iconMetacognitive },
          },
          {
            title: 'Socratic seminars',
            icon: { src: iconSocratic },
          },
          {
            title: 'Audio narration',
            icon: { src: iconAudioNarration },
          },
          {
            title: 'Quizzes',
            icon: { src: iconQuizzes },
          },
          {
            title: 'Posters',
            icon: { src: iconPosters },
          },
          {
            title: 'Videos',
            icon: { src: iconVideos },
          },
          {
            title: 'STEM career profiles',
            icon: { src: iconCareers },
          },
          {
            title: 'Glossary of terms',
            icon: { src: iconGlossary },
          },
          {
            title: 'AR and VR experiences',
            icon: { src: iconAR },
          },
          {
            title: 'Stile X workbook',
            icon: { src: iconStileX },
          },
        ]}
      />

      <NumbersBlock
        title="Stile in numbers"
        figures={[
          {
            count: '53',
            text: 'topics covering the NGSS for middle school and high school',
          },
          {
            count: '800+',
            text: 'digital science lessons and assessments',
          },
          {
            count: '9000+',
            text: 'summative and formative questions, with fully-worked model answers',
          },
          {
            count: '34',
            text: 'pre-tested practical activities using common materials',
          },
          {
            count: '59',
            text: 'career profiles of young, relatable people using their science and engineering skills',
          },
          { count: '44', text: 'custom-built simulations and interactives' },
          { count: '800+', text: 'high-quality short videos, many of which we’ve built ourselves' },
          {
            count: '17',
            text: 'lessons focused on scientific discoveries, news, and current events',
          },
          {
            count: '1000+',
            text: 'word dictionary of scientific terms',
          },
        ]}
      />

      <TextBlock title="Stile was made to augment teachers in the classroom, not sideline them">
        <P>
          Teachers are the single most important part of a student’s education. Stile helps teachers
          perform at their best, saving them time and providing them with powerful learning tools.
        </P>

        <P>
          Many online resources effectively sideline the teacher, leaving students in isolation
          answering volleys of lower-order questions. Stile does the opposite.
        </P>

        <P>
          Stile puts the teacher in the driver’s seat. It was designed to teach with – to give
          teachers complete visibility over their students’ work, real-time analytics on their
          understanding of each concept, and the necessary tools to control the flow of the
          classroom. We believe that this <em>true blended learning</em> approach is the best way to
          make great use of the technology in today’s classrooms.
        </P>
      </TextBlock>

      <MediaBlock layout="montage-two">
        <StaticImage
          src="../../../../stile-shared/assets/images/stile-classroom/us_classroom_04.jpg"
          alt="A group of students completing an experiment using petri dishes"
        />
        <StaticImage
          src="../../../../stile-shared/assets/images/stile-classroom/us_classroom_05.png"
          alt="A teacher explaining an experiment to a student"
        />
      </MediaBlock>

      <NextPageBlock path="/what-is-stile/stile-x/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default StileClassroomPage;
