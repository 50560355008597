import * as React from 'react';

import { animated, config, useTransition } from '@react-spring/web';
import { useInterval } from 'react-use';
import styled, { css } from 'styled-components';

import { getButtonProps } from '../../../../utils/getButtonProps';
import { isEven } from '../../../../utils/isEven';

import { theme } from '../../../../styles/2020/theme';

import { H2, P } from '../../common/Primitives';
import { Clamp } from '../../layout/Clamp';
import { Column } from '../../layout/Column';
import { Frame } from '../../layout/Frame';
import { FullWidthBackground } from '../../layout/FullWidthBackground';
import { Row } from '../../layout/Row';
import { Sidebar } from '../../layout/Sidebar';

import addressBarSvg from './browser_address_bar.svg';

type SlidingToursBlockProps = {
  title: string;
  items: SlideTourProps[];
};

export function SlidingToursBlock({ title, items }: SlidingToursBlockProps) {
  return (
    <FullWidthBackground forwardedAs="section" intrinsic={false} invertColors>
      <Column space={theme.space.section}>
        <Row>
          <H2 size="xxl">{title}</H2>
        </Row>

        {items.map((item, index) => (
          <SlideTour key={item.title} contentSide={isEven(index) ? 'left' : 'right'} {...item} />
        ))}
      </Column>
    </FullWidthBackground>
  );
}

type SlideTourProps = {
  contentSide?: React.ComponentProps<typeof Sidebar>['side'];
  title: string;
  slides: {
    text: string;
    image: string;
  }[];
};

export function SlideTour({ contentSide = 'left', title, slides }: SlideTourProps) {
  const [isPlaying, setIsPlaying] = React.useState(true);
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [resetProgress, setResetProgress] = React.useState(false);

  const transitions = useTransition(slides[activeSlide], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 100,
    config: config.slow,
  });

  const updateSlide = (index: number) => {
    if (index == slides.length) {
      setActiveSlide(0);
      setResetProgress(true);
    } else {
      if (index > 0) {
        setResetProgress(false);
      }
      setActiveSlide(index);
    }
  };

  const content = (
    <Content>
      <Clamp max="600px">
        <Column>
          <H2 as="h3" size="xxl">
            {title}
          </H2>

          <Column space={theme.space.mToL}>
            {slides.map((slide, slideIndex) => (
              <TextWithProgressBar
                key={slideIndex}
                slide={slide}
                index={slideIndex}
                activeSlide={activeSlide}
                updateSlide={updateSlide}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                resetProgress={resetProgress}
                setResetProgress={setResetProgress}
              />
            ))}
          </Column>
        </Column>
      </Clamp>
    </Content>
  );

  return (
    <Slide>
      {contentSide === 'left' && content}

      <Clamp max="600px">
        <Column space={0}>
          <AddressBar src={addressBarSvg} alt="" />
          <Frame aspectRatio="4:3">
            {transitions((style, slide) => (
              <BGImage style={style} image={slide.image} />
            ))}
          </Frame>
        </Column>
      </Clamp>

      {contentSide === 'right' && content}
    </Slide>
  );
}

type TextWithProgressBarProps = {
  slide: SlideTourProps['slides'][number];
  index: number;
  activeSlide: number;
  isPlaying: boolean;
  setIsPlaying: (value: boolean) => void;
  resetProgress: boolean;
  setResetProgress: (value: boolean) => void;
  updateSlide: (index: number) => void;
};

function TextWithProgressBar({
  slide,
  index,
  activeSlide,
  isPlaying,
  setIsPlaying,
  resetProgress,
  setResetProgress,
  updateSlide,
}: TextWithProgressBarProps) {
  const SlideTextRef = React.useRef<HTMLDivElement>(null);
  const [progress, setProgress] = React.useState(0);
  const isActive = activeSlide == index;

  // Allow the "progress" timer to keep ticking if in "isPlaying mode"
  // or if the slide is in screen view
  useInterval(
    () => {
      const parentNode = SlideTextRef?.current?.parentNode as HTMLElement;
      const parentTop = parentNode?.getBoundingClientRect().top;
      const isInViewport = Math.abs(parentTop) < window.innerHeight;

      if (isPlaying && isInViewport) {
        progress == 110 ? updateSlide(index + 1) : setProgress(progress + 1);
      }
    },
    isActive ? 80 : null
  );

  const handleClick = React.useCallback(() => {
    setIsPlaying(false);
    setResetProgress(true);
    updateSlide(index);
  }, [setIsPlaying, setResetProgress, updateSlide, index]);

  React.useEffect(() => {
    if (resetProgress) {
      setProgress(0);
    }
  }, [resetProgress]);

  let displayedProgress;
  if (isPlaying) {
    // normal behaviour as long as nobody clicks on anything
    displayedProgress = progress;
  } else {
    // if current displayed slide was clicked, fill in the progress bar
    // and empty other progress bars
    displayedProgress = activeSlide == index ? 110 : 0;
  }

  return (
    <SlideText ref={SlideTextRef} {...getButtonProps(handleClick)}>
      <Progress isActive={isActive} progress={displayedProgress} />
      <Description isActive={isActive}>{slide.text}</Description>
    </SlideText>
  );
}

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.l};
  align-items: center;

  @media ${theme.media.min.s} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: row wrap;
  }
`;

const Content = styled.div`
  order: -1;

  @media ${theme.media.min.s} {
    order: 0;
  }
`;

const SlideText = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
`;

const BGImage = styled(animated.div)<{ image: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.image});
  border-bottom-left-radius: ${theme.borderRadius.s};
  border-bottom-right-radius: ${theme.borderRadius.s};
`;

const Progress = styled.div<{ isActive: boolean; progress: number }>`
  width: 4px;
  min-width: 4px;
  background-color: ${theme.colors.grey5};
  margin-right: ${theme.space.s};
  position: relative;
  overflow: hidden;

  ${({ progress, isActive }) =>
    isActive &&
    progress &&
    css`
      &::before {
        content: '';
        position: absolute;
        background-color: ${theme.colors.green1};
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        transition: all 0.5s linear;
        transform: scaleY(${progress / 100});
        transform-origin: top center;
      }
    `}
`;

const Description = styled(P)<{ isActive: boolean }>`
  transition-duration: 0.5s;

  ${({ isActive }) => !isActive && `opacity: 0.3;`}

  &:hover {
    opacity: 0.7;
  }
`;

const AddressBar = styled.img`
  display: block;
  width: 100%;
  height: auto;
  border-radius: 0;
`;
